import React from 'react'
import { Layout } from '../components/layout/Layout'
import { HeadingMd } from '../components/heading/Heading'
import { TextBanner } from '../components/text-banner/TextBanner'
import { Faq } from '../components/faq/Faq'

const BehandelingenPage = () => {
  return (
    <Layout title="Meestgestelde vragen" desc="">
      <TextBanner>
        <HeadingMd h={2}>Meestgestelde vragen</HeadingMd>
        <Faq
            title="Kan ik vrijblijvend contact opnemen?"
            text="Natuurlijk, mocht je na het lezen van de website vragen hebben of meer willen weten kun je via de contactknop een afspraak maken."
          />
          <Faq
            title="Waar spreken we af?"
            text="Onze afspraken zijn vooral bij jou thuis, de plek waar ook jouw kind en/of gezin is en jij je veilig en vertrouwd voelt. Uiteraard is het soms ook mogelijk om op een andere locatie af te spreken mocht dit nodig zijn."
          />
          <Faq
            title="Hoe lang duurt een traject?"
            text="Het is lastig te bepalen hoe lang een traject duurt, het is afhankelijk van hoe jij je voelt en wanneer je zelf het idee hebt dat je weer in je kracht staat."
          />
          <Faq
            title="Hoe leg ik mijn proces vast om terug te lezen en te bewaren?"
            text="Samen met de postpartum coach maak je met verhaaltjes en foto's inzichtelijk waar je aan hebt gewerkt, wat het je oplevert en hoe je ermee verder wilt. Bij het afsluiten van het traject zorgen wij voor een portfolio als blijvende herinnering aan jouw proces."
          />
          <Faq
            title="Is er een wachtlijst?"
            text="Nee, op dit moment niet."
          />
      </TextBanner>
    </Layout>
  )
}

export default BehandelingenPage
