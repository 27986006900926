import React from 'react'
import cx from 'classnames'
import * as styles from './Faq.module.css'
import { HeadingXs } from '../heading/Heading'

export function Faq({ title, text }) {
  const [expanded, setExpanded] = React.useState(false)

  return (
    <div className={styles.component}>
      <div className={styles.question}>
        <HeadingXs layoutClassName={styles.heading} h={3}>{title}</HeadingXs>
        <button className={cx(styles.button, expanded && styles.buttonActive)} type="button" onClick={() => setExpanded((x) => !x)}>
          <span className={cx('u-screenreader-text')}>Toggle</span>
        </button>
      </div>
      {expanded && (
        <div className={styles.answer}>
          <p dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      )}
    </div>
  )
}
